<template>
  <Sidebar
    v-model:visible="isCartSidebarVisible"
    :showCloseIcon="false"
    :blockScroll="true"
    class="cart-sidebar w-full lg:w-1/2"
    :position="'right'"
    @hide="emit('close-cart-sidebar')"
  >
    <div class="h-full w-full overflow-hidden">
      <div class="mx-auto flex h-full !w-full flex-col items-center 2xl:max-w-8xl">
        <div class="lg:px- h-full w-full">
          <template v-if="!pointList.length && loading === undefined ? true : loading">
            <CartSkeleton />
          </template>
          <template v-else>
            <template v-if="cartCount === 0">
              <div class="flex h-full w-full items-center justify-center px-8 py-24">
                <p class="text-center">
                  <strong class="mx-auto text-4xl">Seu plano de mídia está vazio</strong>
                  <br />
                  <Button class="mt-5" label="Criar meu anúncio" @click="createAd()" />
                </p>
              </div>
            </template>
            <template v-else>
              <div class="mt-12 flex items-center justify-between px-8 lg:pl-[48px] lg:pr-[116px]">
                <div class="flex items-center">
                  <p class="mr-2 text-[2.67rem] font-bold">Plano de Mídia</p>
                  <CartBadge :cart-items-count="cartCount" class="mt-1" />
                </div>
                <div
                  class="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-neutral-light1 hover:bg-body"
                  @click="emit('close-cart-sidebar')"
                >
                  <i class="uil uil-times mt-1" style="font-size: 2.2rem !important; color: #1f262e !important"></i>
                  <!-- <i class="uil uil-times-circle mt-1 text-black" style="font-size: 2.5rem !important"></i> -->
                </div>
              </div>
              <div class="cart-sidebar h-full min-h-screen overflow-y-auto scroll-smooth !pb-[286px]">
                <div class="mt-4 flex w-full gap-5 px-8 lg:px-[48px]">
                  <MetricCard
                    small
                    :number="items_summary.total_spaces"
                    text="espaços"
                    tooltip="Cada um dos ambientes (Edifícios, Ruas, Transporte)"
                  />
                  <MetricCard
                    small
                    :number="items_summary.total_screens"
                    text="telas"
                    tooltip="Quantidade de telas onde são exibidos os anúncios"
                  />
                  <MetricCard
                    :number="formatNumber(items_summary.total_insertions).reach"
                    :text="isMobile ? 'exibições' : 'exibições do anúncio'"
                    tooltip="Soma total de exibições nos ambientes selecionados"
                  />
                </div>
                <div
                  class="mt-4 flex w-full flex-wrap justify-between px-8 lg:mb-[32px] lg:mt-[38px] lg:pl-[48px] lg:pr-[116px]"
                >
                  <div class="flex">
                    <p class="!text-[1.18rem]">
                      <span class="pr-2 !text-neutral-gray6">Início</span>
                      <span class="pr-5 !font-bold !text-neutral-low-dark">{{ campaignDates.start }}</span>
                    </p>
                    <p class="!text-[1.18rem]">
                      <span class="pr-2 !text-neutral-gray6">Fim</span>
                      <span class="pr-5 !font-bold !text-neutral-low-dark">{{ campaignDates.end }}</span>
                    </p>
                  </div>
                  <p class="!text-[1.18rem]">
                    <span class="pr-2 !text-neutral-gray6">Duração total</span>
                    <span class="!font-bold !text-neutral-low-dark">{{ CampaignDuration[duration]?.name }}</span>
                  </p>
                </div>
                <!-- <template v-if="hasStaticCartItems">
                  <div
                    class="mt-2 flex w-full items-center justify-center bg-status-pending-idle px-16 py-4 text-neutral-low-dark lg:mt-0 lg:py-[20px] lg:text-xl"
                  >
                    <i class="uil uil-info-circle mr-6" style="font-size: 2rem !important"></i>
                    <p>
                      <span class="font-bold">Atenção:</span> As mídias impressas possuem prazo de envio de arte e data
                      de instalação diferenciadas.
                    </p>
                  </div>
                </template> -->
                <div class="flex w-full flex-col px-8 lg:px-0">
                  <div class="w-full">
                    <div class="flex h-full w-full justify-center lg:mt-0">
                      <template v-if="cartItems.length">
                        <div class="flex w-full flex-col">
                          <div
                            class="flex w-full !items-center justify-between pb-2 lg:pl-[48px] lg:pr-[116px] lg:pt-8"
                          >
                            <p class="text-lg">
                              <span class="text-[1.4rem] font-semibold">{{ cartCount }}</span> ponto{{
                                cartCount !== 1 ? "s" : ""
                              }}
                            </p>
                            <div>
                              <Button
                                v-if="cartItems.length > 0"
                                outlined
                                class="without-border !bg-white !text-neutral-gray7"
                                @click="handleCleanCart"
                              >
                                <i class="uil uil-trash mr-2"></i>
                                <span class="text-lg font-bold">limpar plano de mídia</span>
                              </Button>
                            </div>
                          </div>
                          <div class="min-w-[300px]">
                            <template v-for="(point, index) in cartItems" :key="index">
                              <CartItem
                                :name="point.properties.name"
                                :address="`${point.properties.address}, ${point.properties.number}, ${point.properties.region} - ${point.properties.city}/${point.properties.state}`"
                                :photo="point.properties.photo"
                                :midia="point.properties.midia"
                                :exhibition_type="point.properties.exhibition_type"
                                :price="point.total_price"
                                :originalPrice="point.properties.original_price"
                                :period_insertions="point.properties.period_insertions"
                                :maxQuantity="point.properties.screens_max_quantity"
                                :quantity="point.quantity"
                                :days="duration"
                                :allow_multiples_screens="point.properties.allow_multiples_screens"
                                @remove-from-cart="handleRemoveItem(point.id)"
                                @update-quantity="(quantity) => dispatchAddToCart({ ...point, quantity: quantity })"
                              />
                            </template>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <template v-if="pointList.length && !loading">
                  <section
                    class="mb-1 w-full border-t-[1px] border-neutral-light8 px-8 pt-[32px] lg:pl-[48px] lg:pr-[116px]"
                  >
                    <div>
                      <h2 class="text-3xl font-bold">Resumo do pedido</h2>
                      <div class="mt-5 w-full border-b-[1px] border-neutral-light8">
                        <template v-if="coupon.data.is_valid">
                          <div class="mb-[8px] mt-3 flex w-full justify-between text-lg">
                            <p>Subtotal</p>
                            <p>{{ $currency(cartSubtotal) }}</p>
                          </div>
                          <div class="flex w-full justify-between text-lg lg:items-center">
                            <div class="flex flex-wrap items-center">
                              <template v-if="hideBuzzleadCoupon">
                                <p class="mr-2">Desconto Programa de Indicação</p>
                              </template>
                              <template v-else>
                                <p class="mr-2">
                                  Cupom aplicado (<span class="font-bold">{{ coupon.data.code }}</span
                                  >)
                                </p>
                              </template>
                              <Button
                                class="without-border !min-h-0 !p-0"
                                :loading="loadingCoupon"
                                outlined
                                @click="removeCoupon"
                              >
                                <span
                                  class="cursor-pointer text-lg text-status-negative-pastelRed hover:text-status-negative-hover"
                                >
                                  remover
                                </span>
                              </Button>
                            </div>
                            <p class="min-w-fit text-status-positive-light2">- {{ $currency(discounts.data.cart) }}</p>
                          </div>
                        </template>

                        <div v-if="!hideBuzzleadCoupon" class="flex flex-col pb-[12px]">
                          <label
                            v-if="!showCouponInput"
                            class="w-full cursor-pointer underline"
                            for="cupom"
                            @click="showCouponInput = true"
                          >
                            Possui um cupom de desconto?
                          </label>
                          <div class="flex w-full items-center">
                            <div>
                              <div
                                v-if="showCouponInput && !couponForm.valid"
                                class="p-inputgroup my-3 flex w-full flex-col"
                              >
                                <div class="relative w-full min-w-64">
                                  <i class="uil uil-tag text-surface-400 dark:text-surface-600 absolute left-3 top-5" />
                                  <InputText
                                    v-model="couponForm.coupon"
                                    name="cupom"
                                    label="Cupom"
                                    placeholder="Código do cupom"
                                    class="lg:w-4/10 w-full !pl-10"
                                    oninput="this.value = this.value.toUpperCase()"
                                    :disabled="couponForm.valid"
                                    :class="{ 'p-invalid': classCouponInput }"
                                    @keydown.space.prevent
                                    @blur="validateCoupon"
                                  />
                                  <i
                                    class="absolute right-3 top-5 cursor-pointer"
                                    :class="classIconAfterInput"
                                    @click="removeCoupon()"
                                  />
                                </div>
                              </div>
                            </div>
                            <Button
                              v-if="showCouponInput && !coupon.data.is_valid"
                              class="ml-2 flex !h-fit !w-fit justify-center"
                              :loading="loadingCoupon"
                              label="aplicar"
                              :disabled="!couponForm.coupon"
                              @click="validateCoupon"
                            >
                            </Button>
                          </div>
                          <label
                            v-if="labelValidCoupon"
                            class="mb-3 w-4/12 whitespace-nowrap"
                            :class="classValidCoupon"
                          >
                            {{ labelValidCoupon }}
                          </label>
                        </div>
                      </div>
                      <div class="mt-[8px] flex w-full justify-between text-2xl font-bold">
                        <p>Total</p>
                        <p>{{ $currency(Math.max(0, cartTotal)) }}</p>
                      </div>
                    </div>
                  </section>
                </template>
              </div>
              <div
                class="absolute bottom-0 flex !h-auto w-full flex-col justify-end bg-white px-8 pt-4 lg:pl-[48px] lg:pr-[116px] lg:pt-0"
              >
                <div class="flex">
                  <div class="absolute left-0 w-full border-t-[1px] border-neutral-light8"></div>
                </div>
                <div class="w-full items-center py-6">
                  <div class="flex w-full items-center justify-between">
                    <Button
                      class="without-border mr-2 !text-lg font-bold !text-neutral-gray7"
                      label="fechar"
                      severity="secondary"
                      outlined
                      @click="emit('close-cart-sidebar')"
                    />
                    <div class="flex justify-end">
                      <Button class="mr-2 !text-lg !font-bold" @click="redirectToCart()">
                        continuar <i class="uil uil-angle-right ml-2 mt-[1px]"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <DialogUploadForm :campaign="campaignId" :showModal="showDialogUpload" @close-modal="handleCloseModal" />
      <ValidationCampaing />
    </div>
  </Sidebar>
</template>

<script setup>
import "jspdf-autotable";

import CartItem from "@components/CartItem.vue";
import MetricCard from "@components/MetricCard.vue";
import { CampaignDuration } from "@enums/OrderEnum";
import { useStore } from "@nanostores/vue";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { storeToRefs } from "pinia";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { computed, defineEmits, inject, onMounted, reactive, ref, watch } from "vue";

import CartSkeleton from "@/components/Cart/components/CartSkeleton.vue";
import ValidationCampaing from "@/components/Dialogs/ValidationCampaing.vue";
import DialogUploadForm from "@/components/pages/Campaigns/DialogUploadForm.vue";
import { AssetEnum } from "@/enums/AssetEnum";
import { useDeeplink } from "@/hooks";
import useIsMobile from "@/hooks/useIsMobile";
import { rotaCadastro, rotaPlanoDeMidia, rotaResultados } from "@/routes";
import { useCampaignStore } from "@/stores/CampaignStore";
import { useCartStore } from "@/stores/CartStore";
import { userFormulario } from "@/stores/store";
import { useUserStore } from "@/stores/UserStore";
import { getCouponBuzzlead } from "@/utils/envHelper";
import formatNumber from "@/utils/formatNumber";

import CartBadge from "./components/CartBadge.vue";
const userForm = useStore(userFormulario);

const props = defineProps({
  isCartSidebarVisible: {
    type: Boolean,
    default: false,
  },
});

const isCartSidebarVisible = ref(props.isCartSidebarVisible);

const whitelabel = Cookies.get("__wl");

const pointList = ref([]);
const campaignId = ref(null);
const cartTotal = ref(0);
const cartSubtotal = ref(0);
const showDialogUpload = ref(false);
const disableNextButton = ref(true);
const hideBuzzleadCoupon = ref(false);

const deeplink = useDeeplink();

const coupon = reactive({ data: "" });
const discounts = reactive({ data: "" });
const campaignDates = reactive({
  start: "",
  end: "",
});

const dataLayer = inject("datalayer");

const couponByPromotionRoute = JSON.parse(localStorage.getItem("coupon"));

const CartStore = useCartStore();
const CampaignStore = useCampaignStore();
const UserStore = useUserStore();

const { loading, cartCount, cartItems, subtotal, total } = storeToRefs(CartStore);
const { items_summary, id, date_start, duration } = storeToRefs(CampaignStore);

const emit = defineEmits(["update:pontos", "updateCart", "close-cart-sidebar"]);

const labelValidCoupon = ref("");
const classValidCoupon = ref("");
const loadingCoupon = ref(false);
const showCouponInput = ref(false);
const classCouponInput = ref(false);
const couponForm = reactive({
  coupon: "",
  valid: false,
});

const classIconAfterInput = ref("");

const toast = useToast();
const confirm = useConfirm();
const { isMobile } = useIsMobile();

const couponBuzzlead = getCouponBuzzlead();

const createAd = () => {
  if (window.location.pathname === rotaResultados.url) {
    return (isCartSidebarVisible.value = false);
  }

  return (document.location.href = rotaResultados.url);
};

const dispatchAddToCart = (data) => {
  CartStore.addToCart(data);
};

const dispatchRemoveItemFromCart = (id) => {
  CartStore.removeItemFromCart({ item_ids: [id] });
};

const dispatchRemoveAllItemsFromCart = () => {
  CartStore.removeAllItemsFromCart();
};

const handleCloseModal = (e) => (showDialogUpload.value = e);

const dispatchFetchCart = async () => {
  CartStore.fetchCart();
};

const handleCleanCart = async () => {
  confirm.require({
    group: "headless",
    message: "Tem certeza de que deseja limpar o plano de mídia?",
    header: "Atenção",
    icon: "uil uil-trash",
    acceptLabel: "remover item",
    rejectLabel: "voltar",
    accept: () => {
      dataLayer("remove_from_cart", pointList.value);
      dispatchRemoveAllItemsFromCart();
    },
    reject: () => {},
  });
};

const handleRemoveItem = async (id) => {
  confirm.require({
    group: "headless",
    message: "Tem certeza de que deseja remover esse item do plano de mídia?",
    header: "Atenção",
    icon: "uil uil-trash",
    acceptLabel: "Sim",
    rejectLabel: "Não",
    accept: () => {
      dispatchRemoveItemFromCart(id);
      dataLayer("remove_from_cart", [pointList.value.find((objeto) => objeto.id === id)]);
    },
    reject: () => {},
  });
};

/**
 * minimo necessario para funcionar
 *
 * @param {{
 *   coupon: {code:string, discount:int, is_valid:boolean}[],
 *   discounts: {cart:string|int}
 *   amounts: {total:int, subtotal:int},
 * }} payload
 */
const addCouponModifications = (payload) => {
  const couponData = payload.coupon?.[0];
  const discountsData = payload.discounts;
  if (!couponData?.is_valid) {
    return;
  }

  coupon.data = couponData;
  discounts.data = discountsData;
  cartSubtotal.value = payload?.subtotal ?? 0;
  cartTotal.value = payload?.total ?? 0;

  couponForm.coupon = couponData.code;
  couponForm.valid = true;
  showCouponInput.value = true;

  labelValidCoupon.value = `você ganhou ${couponData.discount * 100}% de desconto!`;
  classValidCoupon.value = "";
  classIconAfterInput.value = "uil uil-times";
  classCouponInput.value = false;
};

const removeCouponModifications = (message) => {
  coupon.data = "";
  discounts.data = "";
  couponForm.coupon = "";
  couponForm.valid = false;
  loadingCoupon.value = false;
  showCouponInput.value = false;

  cartTotal.value = cartSubtotal.value;
  cartSubtotal.value = 0;

  labelValidCoupon.value = message ?? "";
  if (message) {
    classValidCoupon.value = "text-invalid";
    classIconAfterInput.value = "uil uil-times";
    classCouponInput.value = true;
  }
};

const validateCoupon = async () => {
  //refatorar para o novo padrão de Stores e requests
  let url = "/apis/Core/cart-update";
  labelValidCoupon.value = "verificando...";
  classIconAfterInput.value = "uil uil-arrow-right";
  classValidCoupon.value = "";
  let couponData = {
    email: UserStore.google_auth.email || UserStore.email,
    coupon: couponForm.coupon,
  };

  loadingCoupon.value = true;
  const res = await axios.post(url, couponData);
  const couponResult = res.data?.coupon?.[0];
  const isCouponValid = couponResult?.is_valid;

  try {
    if (isCouponValid) {
      addCouponModifications(res.data);
      toast.add({
        severity: "success",
        summary: "Cupom aplicado com sucesso!",
        life: 3000,
      });
    } else if (!isCouponValid && couponForm.coupon != "") {
      removeCouponModifications(couponResult.message, res.data?.total);

      toast.add({
        severity: "error",
        summary: "O cupom não é válido!",
        life: 3000,
      });
    }

    deeplink.clear();
  } catch (error) {
    console.error("coupon error request: ", error);
    removeCouponModifications("Este código é inválido", res.data?.total);
  } finally {
    loadingCoupon.value = false;
  }
};

const removeCoupon = () => {
  loadingCoupon.value = true;

  let url = "/apis/Core/cart-update";
  let couponRequestData = {
    email: UserStore.email || UserStore.google_auth.email,
    coupon: "",
  };

  loadingCoupon.value = true;

  axios.post(url, couponRequestData).then(() => {
    removeCouponModifications();
    CartStore.clearCoupon();

    toast.add({
      severity: "success",
      summary: "Cupom removido com sucesso!",
      life: 3000,
    });
  });
};

const redirectToCart = async () => {
  document.location.href = rotaPlanoDeMidia.url;
  return;
};

const hasStaticCartItems = computed(() =>
  cartItems.value.some((item) => item.properties?.exhibition_type === AssetEnum.static),
);

const handleSidebarTopPosition = () => {
  const header = document.querySelector("header");
  if (!header) return;

  const headerHeight = `${header.offsetHeight}px`;
  const sidebarHeight = `calc(100% - ${headerHeight})`;

  const sidebarMask = document.querySelector(
    ".p-sidebar-mask.p-component-overlay.p-component-overlay-enter.p-sidebar-visible.p-sidebar-right",
  );

  if (!sidebarMask) return;

  sidebarMask.style.top = headerHeight;
  sidebarMask.style.height = sidebarHeight;
  sidebarMask.classList.add("apply-zoom");
};

watch(cartItems, () => {
  pointList.value = new Proxy(cartItems.value, {});
});

watch([cartItems, cartCount, subtotal, total, id, date_start, duration, coupon, total, subtotal], () => {
  if (cartCount.value > 0) {
    addCouponModifications(CartStore.$state);
    campaignId.value = id.value;
    coupon.value = coupon[0] ? coupon[0] : "";
    hideBuzzleadCoupon.value = coupon.data.code == couponBuzzlead;

    campaignDates.start = moment(date_start.value).format("DD/MM/YYYY");
    campaignDates.end = moment(date_start.value).add(duration.value, "d").subtract(1, "d").format("DD/MM/YYYY");
    pointList.value = cartItems.value;
  }
  disableNextButton.value = !cartCount.value > 0;
});

watch([total, subtotal], () => {
  cartSubtotal.value = subtotal.value;
  cartTotal.value = total.value;
});

watch(cartItems, () => {
  pointList.value = new Proxy(cartItems.value, {});
});

watch(
  () => props.isCartSidebarVisible,
  (newValue) => {
    isCartSidebarVisible.value = newValue;
    setTimeout(() => {
      handleSidebarTopPosition();
    }, 0);
  },
);

onMounted(async () => {
  await dispatchFetchCart();

  cartSubtotal.value = subtotal.value;
  cartTotal.value = total.value;

  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get("from") === "register" && cartCount.value > 0) {
    showDialogUpload.value = true;
  }

  const deepLinkCouponCode = deeplink.prop("dl_coupon");
  const isDeeplinkCouponValid = deeplink.isValid(() => {
    const isCartFilled = cartCount.value > 0;
    return isCartFilled && deepLinkCouponCode;
  });

  if (isDeeplinkCouponValid || couponByPromotionRoute) {
    showCouponInput.value = true;
    couponForm.coupon = deepLinkCouponCode || couponByPromotionRoute;
    await validateCoupon();
  }
});
</script>

<style lang="scss" scoped>
.showForms-enter-active,
.showForms-leave-active {
  transition: all 0.25s ease-out;
}

.showForms-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.showForms-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>

<style lang="scss">
.cart-sidebar {
  .p-sidebar-header {
    @apply hidden;
  }
  .p-sidebar-content {
    @apply p-0;
  }
}

.cart-sidebar::-webkit-scrollbar {
  display: none;
}

.cart-sidebar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.p-button.without-border {
  border: none !important;
}
</style>
