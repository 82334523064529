<template>
    <div class="w-7 h-7 rounded-full flex items-center justify-center bg-green-500 text-white">
      {{ props.cartItemsCount }}
    </div>
  </template>
  
  <script setup lang="ts">
    interface CartBadgeInterface {
      cartItemsCount: string;
    }
  
    const props = withDefaults(defineProps<CartBadgeInterface>(), {
        cartItemsCount: '',
    })
  </script>
  